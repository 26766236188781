import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private http: HttpClient,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let url: string = state.url;
        console.log(route.url);
        return this.verifyLogin(url);
    }

    verifyLogin(url){


        const loggedIn = this.isLoggedIn();
    
        if(!loggedIn){
            this.router.navigate(['/account/login']);
            return false;
        }
        else if(loggedIn){
            return true;
        }
    }
    public isLoggedIn(){
         let status = false;
         if( sessionStorage.getItem('isLoggedIn') == "true"){
           status = true;
         }
         else{
          status = false;
         }
         return status;

        //return success;
        // const currentUser = this.authenticationService.currentUser();
        // if (currentUser) {
        //     // logged in so return true
        //     return true;
        // }
        // return true;
        // else
        // {
        //     console.log('else');
        // // not logged in so redirect to login page with the return url
        //     //this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        //     return false;
        // }
    }
}