import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../services/cookie.service';
import { User} from '../models/auth.models';
import { Responses } from '../models/response.models';

@Injectable({ providedIn: 'root'})
export class AuthenticationService {
    user: User = null;

    message:string;

    error:'';

    errorData: {};

    readonly rootUrl =`${environment.apiUrl}auth/login`;
    loading = false;

    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        // if (!this.user) {
        if(sessionStorage.getItem('currentUser')){
            //this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
            this.user = JSON.parse(sessionStorage.getItem('currentUser'));
        }
        console.log(this.user);
        return this.user;
    }

    isLoggedIn() {
        if (sessionStorage.getItem('currentUser')) {
          return true;
        }
        return false;
      }
    
      getAuthorizationToken() {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        return currentUser.token;
      }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    async login(email: string, password: string) {
        
        try{

            return  await this.http.post<Responses>(this.rootUrl, { email, password }).toPromise();
        }
        catch(e){                
            return  { success:false, message:e.message||e, result:null}    
        }     
    }

    /**
     * Logout the user
     */

    logout(): void {
        sessionStorage.setItem('isLoggedIn', "false");
        sessionStorage.removeItem('token');
      } 
}

