import { Injectable, PipeTransform } from '@angular/core';
import {HttpClient,HttpErrorResponse} from '@angular/common/http';
import {environment} from '@environments/environment';

import {  Observable } from 'rxjs';

import "rxjs/add/operator/catch";
import "rxjs/add/operator/do";
import 'rxjs/add/operator/map';

import { Responses } from 'src/app/core/models/response.models';



@Injectable({
  providedIn: 'root'
})

export class ProfileService {

  public baseurl=`${environment.apiUrl}auth/session`;

  constructor(private _http:HttpClient) {
  
  }


     //exceptional handler
private handleError(err:HttpErrorResponse)
  {
    console.log("handle error Http calls");
    console.log(err.message);
    return Observable.throw(err.message);
  }

  
  public async profileinfo(){
    const token = sessionStorage.getItem('token');
  
    const {result}= await this._http.post<Responses>(this.baseurl,
      {} ,{ headers: { 'X-Token':  token } })
    .toPromise();

    //alert(JSON.stringify(result));

    return result;
  } 

}


  

